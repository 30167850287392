<template>
    <div class="info">
      <top-bar :title="'新增报修'" :left="true"></top-bar>

      <van-popup v-model="sourceShow"   position="bottom"  >
        <van-picker title="请选择" show-toolbar :columns="selectData.sourceList"  value-key="label"  @confirm="sourceConfirm"  @cancel="sourceShow = false"/>
      </van-popup>

      <van-popup v-model="categoryshow"   position="bottom"  >
        <van-picker title="请选择" show-toolbar :columns="selectData.categoryList"  value-key="label"  @confirm="categoryConfirm"  @cancel="categoryshow = false"/>
      </van-popup>

      <van-popup v-model="CommunityShow"  position="bottom"  >
        <van-picker title="请选择" show-toolbar :columns="selectData.CommunityList" value-key="name" @confirm="CommunityConfirm"  @cancel="CommunityShow = false"/>
      </van-popup>

      <van-popup v-model="positionShow"   position="bottom"  >
        <van-picker title="请选择" show-toolbar :columns="selectData.positionList" value-key="label"  @confirm="positionConfirm"  @cancel="positionShow = false"/>
      </van-popup>

      <van-popup v-model="BuildingShow"   position="bottom"  >
        <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o" v-if="selectData.BuildingList.length < 1 ">没有获取到楼幢信息,请先选择小区或切换小区</van-notice-bar>
        <van-picker title="请选择" show-toolbar :columns="selectData.BuildingList" value-key="label"  @confirm="BuildingConfirm"  @cancel="BuildingShow = false"/>
      </van-popup>

      <van-popup v-model="unitShow"   position="bottom"  >
        <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o" v-if="selectData.BuildingList.length < 1 ">没有获取到单元信息,请先选择楼幢或切换楼幢</van-notice-bar>
        <van-picker title="请选择" show-toolbar :columns="selectData.unitList" value-key="label"  @confirm="unitConfirm"  @cancel="unitShow = false"/>
      </van-popup>

      <van-popup v-model="houseShow"   position="bottom"  >
        <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o" v-if="selectData.BuildingList.length < 1 ">没有获取到房屋信息,请先选择单元或切换单元</van-notice-bar>
      <van-picker title="请选择" show-toolbar :columns="selectData.houseList" value-key="label"  @confirm="houseConfirm"  @cancel="houseShow = false"/>
      </van-popup>
      <van-cell-group>
        <van-cell>
          <template #title>
            <div class="blue-box"></div>
            <span class="custom-title">报修信息</span>
          </template>
        </van-cell>
        <van-cell title="报修来源" :value="selectMsg.source" @click="sourceShow = true"
                  :value-class="{'value-common':selectMsg.source=='请选择'}" is-link/>
        <van-cell title="报修类别" :value="selectMsg.category" @click="categoryshow = true"
                  :value-class="{'value-common':selectMsg.category=='请选择'}" is-link/>
        <van-cell title="报修性质" :value="selectMsg.position" @click="positionShow = true"
                  :value-class="{'value-common':selectMsg.position=='请选择'}" is-link/>
        <van-cell title="所在小区" :value="selectMsg.Community" @click="CommunityShow = true"
                  :value-class="{'value-common':selectMsg.Community=='请选择'}" is-link/>
        <van-cell title="所在楼幢" :value="selectMsg.Building" @click="BuildingShow = true" v-if="selectMsg.position == '居民家庭维修'"
                  :value-class="{'value-common':selectMsg.Building=='请选择'}" is-link/>
        <van-cell title="所在单元" :value="selectMsg.unit" @click="unitShow = true" v-if="selectMsg.position == '居民家庭维修'"
                  :value-class="{'value-common':selectMsg.unit=='请选择'}" is-link/>
        <van-cell title="所在房屋" :value="selectMsg.house" @click="houseShow = true" v-if="selectMsg.position == '居民家庭维修'"
                  :value-class="{'value-common':selectMsg.house=='请选择'}" is-link/>
        <van-field clearable clear-trigger="always" v-model="selectMsg.description" rows="3" autosize type="textarea" :border="false" placeholder="请输入报修描述" class="left" />
        <van-row class="uploadBox">
          <van-col :span="24">
            <van-uploader v-model="fileList" @delete="uploaddel" :after-read="uploadRead"/>
          </van-col>
        </van-row>
      </van-cell-group>
      <van-row class="btns">
        <van-col :span="24">
          <van-button type="info" size="large" round @click="submit">完成</van-button>
        </van-col>
      </van-row>
    </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import {getbelongSubArea, getDictTree} from '@/utils/common'

import { mapState } from 'vuex'
export default {
  data () {
    return {
      file:{},
      detailData: {},
      sourceShow: false,
      categoryshow: false,
      CommunityShow: false,
      positionShow: false,
      BuildingShow: false,
      unitShow: false,
      houseShow: false,
      selectMsg: {
        category: '请选择',
        Community: '请选择',
        position: '请选择',
        Building: '请选择',
        house: '请选择',
        unit: '请选择',
        description: '',
        source: '社区自查'
      },
      submitData: {
        buildingId:'',
        repairDes:'',
        deleteFiles: [],
        fileMessages:[],
        newFileMessages: [],
        houseId:'',
        publicPlace:'',
        source: 1,
        id: 0,
        orgId:0,
        subarea:'',
        repairType:'',
        unitId:'',
        userId:'',
        source: 1
      },
      selectData: {
        sourceList: [{label: "上级督查", value: 3}, {label: "社区自查", value: 1}],
        categoryList: [],
        CommunityList: [],
        positionList: [{ label: "公共区域", value: "1" }, { label: "居民家庭维修", value: "0" }],
        BuildingList: [],
        unitList: [],
        houseList: []
      },
      fileList: [],
    }
  },
  computed:{...mapState(['ProblemId'])},
  components :{
    topBar
  },
  methods: {
    // 删除图片
    uploaddel (file, detail) {
      let i = detail.index
      this.submitData.fileMessages.splice(i, 1)
    },
    // 上传图片
    uploadRead (file) {
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration:0,
        overlay: true
      });
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
      let formdata = new FormData();
      formdata.append("file", item.file);
      formdata.append("path", 'files/repair/temporary');
      this.$http({
        url: this.$http.adornUrl('/wxapp/file/upload'),
        method: 'post',
        data: formdata
      }).then(({data})=> {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
          if (data.code == 0) {
            this.submitData.fileMessages.push(data.fileMessage)
            this.submitData.newFileMessages = this.submitData.fileMessages
            return true
          } else {
          this.$toast.fail(data.msg);
          }
        })
      })
    },
    init () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/org/subAreaList'),
        method: 'post',
        params: this.$http.adornParams({
          communityId: this.$orgId
        },false)
      }).then(({data})=> {
          if (data.code == 0) {
            this.selectData.CommunityList = data.subAreaList
          } else {
          this.$toast.fail(data.msg);
          }
        })

      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.$orgId,
          code: 'repairType'
        },false)
      }).then(({data})=> {
          if (data.code == 0) {
            this.selectData.categoryList = data.dicts
          } else {
          this.$toast.fail(data.msg);
          }
        })
    },
    submit () {
      if (this.selectMsg.source === '请选择') {return this.$toast.fail('失败:请先选择报修来源');}
      if (this.selectMsg.category === '请选择') {return this.$toast.fail('失败:请先选择事件类型');}
      if (this.selectMsg.Community === '请选择') {return this.$toast.fail('失败:请先选择小区');}
      if (this.selectMsg.position === '请选择') {return this.$toast.fail('失败:请先选择位置');}
      if (this.selectMsg.Building === '请选择' && this.submitData.publicPlace == 2) {return this.$toast.fail('失败:请先选择楼幢');}
      if ( this.selectMsg.unit === '请选择' && this.submitData.publicPlace == 2) {return this.$toast.fail('失败:请先选择单元');}
      if ( this.selectMsg.house === '请选择' && this.submitData.publicPlace == 2) {return this.$toast.fail('失败:请先选择房屋');}
      if (this.selectMsg.description === '') {return this.$toast.fail('失败:请先输入问题描述');}
      this.submitData.repairDes = this.selectMsg.description
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/commonApp/repair/info/save'),
        method: 'post',
        data : this.$httpApp.adornData({...this.submitData })
      }).then(({data})=> {
          if (data.code == 0) {
          this.$toast.success ('新增成功');
          this.$router.go(-1)
          } else {
          this.$toast.fail(data.msg);
          }
        })
    },
    // 问题类型选择
    categoryConfirm (value) {
      if (value){
      this.selectMsg.category = value.label
      this.submitData.repairType = value.value
      }
      this.categoryshow = false
    },
    sourceConfirm (value) {
      if (value){
      this.selectMsg.source = value.label
      this.submitData.source = value.value
      }
      this.sourceShow = false
    },
    // 小区选择
    CommunityConfirm (value) {
      if (value) {
        this.selectMsg.Community = value.name
        this.submitData.subarea = value.id
        this.selectMsg.Building = '请选择'
        this.selectMsg.unit = '请选择'
        this.submitData.unitId = ''
        this.selectData.unitList = []
        this.selectData.houseList = []
        this.selectMsg.house = '请选择'
        this.submitData.houseId = ''
        this.selectData.BuildingList = []
        this.$http({
        url: this.$http.adornUrl('/wxapp/building/info/getBySubArea'),
        method: 'post',
        params: this.$http.adornParams({
          subArea: value.id
        },false)
      }).then(({data})=> {
          if (data.code == 0) {
            this.selectData.BuildingList = data.buildings
          } else {
          this.$toast.fail(data.msg);
          }
        })
      }
      this.CommunityShow = false
    },
    // 当前位置选择
    positionConfirm (value) {
      if (value) {
        this.selectMsg.position = value.label
        this.submitData.publicPlace = value.value
        this.selectMsg.unit = '请选择'
        this.submitData.unitId = ''
        this.selectMsg.house = '请选择'
        this.submitData.houseId = ''
      }
        this.positionShow = false
    },
    // 楼栋选择
    BuildingConfirm (value) {
      if (value) {
      this.selectMsg.Building = value.label
      this.submitData.buildingId = value.value
      this.selectMsg.unit = '请选择'
      this.submitData.unitId = ''
      this.selectData.houseList = []
      this.selectMsg.house = '请选择'
      this.submitData.houseId = ''
      }
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/unit/listByBuildingId'),
        method: 'post',
        params: this.$http.adornParams({
          buildingId: value.value
        },false)
      }).then(({data})=> {
          if (data.code == 0) {
            this.selectData.unitList = data.units
          } else {
          this.$toast.fail(data.msg);
          }
      })
      this.BuildingShow = false
    },
    // 单元选择
    unitConfirm (value) {
      if(value) {
      this.selectMsg.unit = value.label
      this.submitData.unit = value.value
      this.selectMsg.house = '请选择'
      this.submitData.houseId = ''
      }
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/listByUpId'),
        method: 'post',
        params: this.$http.adornParams({
          upId: value.value,
          bOru: 2
        },false)
      }).then(({data})=> {
          if (data.code == 0) {
            console.log(data);
            this.selectData.houseList = data.houseOptions
          } else {
          this.$toast.fail(data.msg);
          }
      })
      this.unitShow = false
    },
    // 房屋选择
    houseConfirm (value) {
      if(value) {
      this.selectMsg.house = value.label
      this.submitData.houseId = value.value
      }
      this.houseShow = false
    },
    onCancel() {
    },
  },
  created () {
    document.querySelector('html').style.backgroundColor = '#fff'
    this.submitData.orgId = this.$orgId
    this.submitData.userId = this.$globalData.userInfo.userId
    this.init()
  },
  beforeDestroy () {
    document.querySelector('html').style.backgroundColor = ''
  }
}
</script>
